import '../css/front.scss';
import '../css/footer.scss';
import "../css/style-news.scss";
import "../css/style-news-detail.scss";
import angularApp from "./core";

$(document).ready(function ($) {
    function createCookie(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        } else var expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    var cookieDiv = $("#cookie-div");
    var acceptCookie = $("#accept-cookie");
    var refuseCookie = $("#refuse-cookie");
    var cookieAccepted = readCookie("rgpdconsent");
    if (cookieAccepted == null) {
        createCookie("rgpdconsent", null, 21);
    }
    cookieAccepted = readCookie("rgpdconsent");
    if (cookieAccepted == "null") {
        cookieDiv.css("display", "flex");
        acceptCookie.click(function () {
            createCookie("rgpdconsent", true, 21);
            cookieDiv.css("display", "none");
        });
        refuseCookie.click(function () {
            createCookie("rgpdconsent", false, 21);
            cookieDiv.css("display", "none");
        });
    }

    var menuContainer = $("#menu-container");
    var mobileMenu = $("#responsive-menu");

    $("#responsive-menu").click(function (event) {
        if (mobileMenu.hasClass("open")) {
            mobileMenu.removeClass("open");
            menuContainer.removeClass("open");
        } else {
            mobileMenu.addClass("open");
            menuContainer.addClass("open");
        }
    });
});

angularApp.controller('CurrencyComparatorController', ['$scope', function ($scope) {
    function matchCustom(params, data) {
        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '') {
            return data;
        }

        // Do not display the item if there is no 'text' property
        if (typeof data.text === 'undefined') {
            return null;
        }

        // `params.term` should be the term that is used for searching
        // `data.text` is the text that is displayed for the data object
        if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
            return $.extend({}, data, true);
        }

        // Return `null` if the term should not be displayed
        return null;
    }

    function formatState(opt) {
        if (!opt.id) {
            return opt.text.toUpperCase();
        }

        var optimage = $(opt.element).attr('data-image');
        if (!optimage) {
            return opt.text.toUpperCase();
        } else {

            var imgUrl = baseUrl + optimage;

            var $opt = $(
                "<span>" + opt.text.toUpperCase() + "</span> <div class='country-flag-img' style='background-image: url(" + imgUrl + ");'></div>"
            );
            return $opt;
        }
    }

    $scope.init = function () {
        $(".select-custom select").select2({
            matcher: matchCustom,
            data: $(this).map(function () {
                return this.value;
            }).get(),
            templateResult: formatState,
            templateSelection: formatState
        });
    };
}]);

angularApp.controller('FlashController', ['$scope', function ($scope) {
    $scope.showFlash = true;

    $scope.closeFlash = function () {
        $scope.showFlash = false;
    };
}]);

angularApp.controller('CryptoCurrencyComparatorController', ['$scope', function ($scope) {
    $scope.cryptocurrencyFrom = null;
    $scope.cryptocurrencyTo = null;

    $('#cryptocurrency-from').on('changed.bs.select', function (e, index, newVal, oldVal) {
        $('#cryptocurrency-to').find('[style="display:none;"]').show();
        $('#cryptocurrency-to').find('[value=' + $scope.cryptocurrencyFrom + ']').hide();
        $('#cryptocurrency-to').selectpicker('refresh');
    });
    $('#cryptocurrency-to').on('changed.bs.select', function (e, index, newVal, oldVal) {
        $('#cryptocurrency-from').find('[style="display:none;"]').show();
        $('#cryptocurrency-from').find('[value=' + $scope.cryptocurrencyTo + ']').hide();
        $('#cryptocurrency-from').selectpicker('refresh');
    });
}]);

angularApp.controller("SimpleChartController", ["$scope", function ($scope) {
    var chart = null;

    $scope.init = function (jsonRates, color, currencyFrom, currencyTo) {
        var data = JSON.parse(jsonRates);

        var i = 0;
        var dataLength = data.length;
        var lastCHFEURRate = data[dataLength - 1][1];
        let firstCHFEURRate = data[0][1];
        let isUp = lastCHFEURRate >= firstCHFEURRate;
        var ohlc = [];
        var d = new Date();

        for (i; i < dataLength; i += 1) {
            ohlc.push([
                (data[i][0]), // the date
                data[i][1] // open
            ]);
        }
        Highcharts.theme = {
            colors: [isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)"],
            lang: {
                months: [
                    'Janvier', 'Février', 'Mars', 'Avril',
                    'Mai', 'Juin', 'Juillet', 'Août',
                    'Septembre', 'Octobre', 'Novembre', 'Décembre'
                ],
                weekdays: [
                    'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
                    'Jeudi', 'Vendredi', 'Samedi'
                ]
            },
            chart: {
                backgroundColor: "transparent"
            },
            scrollbar: {
                enabled: false
            },
            title: {
                style: {
                    color: "#333333",
                    font: '700 var(--fs-32) "Muli"',
                    marginBottom: 40,
                }
            },
            subtitle: {
                style: {
                    color: "#333333",
                    font: '700 16px "Muli"'
                }
            }
        };


        Highcharts.setOptions(Highcharts.theme);
        // create the chart
        chart = Highcharts.stockChart('chart', {
            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            title: {
                text: '1 ' + currencyFrom + ' = ' + lastCHFEURRate + ' ' + currencyTo,
                y: 15,
                x: 0,
                align: "left"
            },
            subtitle: {
                text: d.toLocaleDateString(),
                y: 50,
                align: "left"
            },
            xAxis: {
                labels: {
                    style: {
                        color: "#615E83",
                        fontWeight: 400
                    }
                },
                type: 'datetime',
                //min: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()),
                //max: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() + 1),
                ordinal: false,
            },
            yAxis: [{
                labels: {
                    align: 'left',
                    x: -50,
                    style: {
                        color: "#615E83",
                        fontWeight: 400
                    },
                    format: '{value:.4f}'
                },
                opposite: false,
                height: '100%',
                lineWidth: 2,
                resize: {
                    enabled: false
                }
            }],
            chart: {
                zoomType: "x",
                marginRight: 0,
                marginLeft: 50,
                marginTop: 100
            },
            series: [{
                type: 'areaspline',
                threshold: null,
                color: isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)",
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)"],
                        [1, isUp ? "rgba(224, 255, 158, 0)" : "rgba(253, 191, 198, 0)"]
                    ]
                },
                name: currencyFrom + '/' + currencyTo,
                data: ohlc,
            }],
        });
    };

    /*
    chart = Highcharts.stockChart('chart', {
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        title: {
            text: d.toLocaleDateString(),
            y: 15,
            x: 0,
            align: "left",
            style: {
                color: "#333333",
                font: '700 32px "Muli"',
                marginBottom: 40,
            }
        },
        subtitle: {
            text: '1 ' + currencyFrom + ' = ' + lastCHFEURRate + ' ' + currencyTo,
            y: 50,
            align: "left",
            style: {
                color: "#333333",
                font: '700 16px "Muli"'
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: "#615E83",
                    fontWeight: 400
                }
            },
            type: 'datetime',
            //min: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()),
            //max: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() + 1),
            ordinal: false,
        },
        yAxis: [{
            labels: {
                align: 'left',
                x: -50,
                style: {
                    color: "#615E83",
                    fontWeight: 400
                },
                format: '{value:.4f}'
            },
            opposite: false,
            height: '100%',
            min: data[0][1],
            max: data[data.length - 1][1],
            lineWidth: 2,

        }],
        chart: {
            marginRight: 0,
            marginLeft: 50,
            marginTop: 100
        },
        series: [{
            type: 'areaspline',
            treshold: null,
            color: color,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, "rgba(177, 239, 46, 0.5)"],
                    [1, "rgba(224, 255, 158, 0)"]
                ]
            },
            name: currencyFrom + '/' + currencyTo,
            data: ohlc,
        }],
    });
     */


}]);

angularApp.controller('MoreNewsController', ['$scope', function ($scope) {
    $scope.init = function () {
        $(function () {
            $('span.btn.show-more').click(function () {
                $('.tab-pane.active.show .news-block:hidden').slice(0, 4).css("display", "flex");
                if ($('.tab-pane.active.show .news-block').length == $('.tab-pane.active.show .news-block:visible').length) {
                    $('.tab-pane.active.show span.btn.show-more').hide();
                }
            });
        });
    };
}]);

angularApp.controller('CryptoFilterController', ['$scope', function ($scope) {
    $scope.filterText = "";

    $scope.showCrypto = function (name, code) {
        var filter = $scope.filterText.toLowerCase().replace(" ", "");
        return name.toLowerCase().replace(" ", "").includes(filter) || code.toLowerCase().replace(" ", "").includes(filter);
    };

    $scope.showPartner = function (name) {
        var filter = $scope.filterText.toLowerCase().replace(" ", "");
        return name.toLowerCase().replace(" ", "").includes(filter);
    };
}]);

angularApp.controller('CryptoCurrencyPairController', ['$scope', function ($scope) {
}]);

angularApp.controller("CryptoAndCurrencyRateController", ["$scope", function ($scope) {
    $scope.cryptos = [];
    $scope.currencies = [];

    $scope.init = function () {
        getCryptoAndCurrenciesRate();
    }

    function getCryptoAndCurrenciesRate() {
        $.ajax({
            url: baseUrl + "/crypto-and-currency-rate"
        }).done(function (data) {
            $scope.cryptos = data.cryptos;
            $scope.currencies = data.currencies;
            $scope.$apply();
        });
    }
}])